<template>
  <v-container class="container">
    <v-breadcrumbs :items="breadcrumbItems"/>
    <v-card>
        <Header title="Link Slack"/>

        <v-card-title>Select the Event to link with your Slack channel.</v-card-title>
        <v-card-text v-if="events">
            <p class="mb-0">Select the event you want to link with your Slack channel. This list will show all events you have access to.</p>
            <p v-if="events.length == 0" class="mb-0">You haven't created an event yet.</p>

            <v-radio-group v-model="selectedEventId">
              <v-radio v-for="(item, idx) in events" :key="idx" :label="item.name" :value="item.id"/>
            </v-radio-group>

            <p>
                <v-btn color="primary" :disabled="!selectedEventId" @click="linkWithSlack">Link with Slack Channel</v-btn>
                <v-btn outlined color="primary" class="ml-4" :to="{name:'eventmanagerCreate', query: {slackChannel: $route.query.slackChannel}}">Create New Event</v-btn>
            </p>
            <br/>
        </v-card-text>
        <div v-else>
            <v-skeleton-loader v-if="!user || $store.getters.loading" type="table"></v-skeleton-loader>
        </div>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import EventGrid from "@/components/EventGrid.vue";
import Header from './_Header.vue'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Profile",
  components: {
    EventGrid,
    Header,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      profile: null,
      events: null,
      selectedEventId: null,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });

    console.log('link with slack', this.$route.query.slackChannel);

  },
  methods: {
    async getProfile() {
      if (this.user) {
        //var response = await profileService.get(/*full:*/false);
        //this.profile = response.data;
        this.events = (await eventManagerService.getOwnedEvents()).data.data;
      }
    },

    async linkWithSlack() {
      console.log('link with slack', this.selectedEventId, this.$route.query.slackChannel);
      var response = await eventManagerService.linkWithSlack(this.selectedEventId, this.$route.query.slackChannel);
      this.$helpers.toastResponse(this, response.data, 'Event is linked with slack channel.');
      if (response.data.status === 'OK') {
        this.$router.push({name: 'eventmanagerIntegrationsSlack', params: {id:this.selectedEventId }});
      }
    },
  },
  computed: {
    breadcrumbItems() {
      return [
        { text: 'Event Manager', disabled: true },
        { text: 'Link With Slack', disabled: true },
      ];
    },
    ...mapGetters({
      user: "user"
    })
  },

};
</script>
<style lang="scss">
</style>

